<template>
  <div class="bills">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-select v-model="orderType" placeholder="选择订单类型" @change="onSearch" clearable>
          <el-option label="线下订单" value="1"></el-option>
          <el-option label="线上配送单" value="2"></el-option>
          <el-option label="线上自提单" value="3"></el-option>
          <el-option label="充值" value="4"></el-option>
        </el-select>
        <el-select v-model="billType" placeholder="选择动账类型" @change="onSearch" clearable>
          <el-option label="充值" value="1"></el-option>
          <el-option label="消费" value="2"></el-option>
          <el-option label="退款" value="3"></el-option>
        </el-select>
        <el-input v-model="memberName" placeholder="搜索会员名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="memberCode" placeholder="搜索会员码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="orderCode" placeholder="搜索订单编号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <!-- <el-date-picker v-model="billTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker> -->
        <el-date-picker v-model="billTime" @change="onChange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="memberName" label="会员名称"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="150" prop="memberCode" label="会员码"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="orderCode" label="订单编号"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="" label="订单类型">
            <template slot-scope="scope">
              <span v-if="scope.row.orderType == 1">线下订单</span>
              <span v-if="scope.row.orderType == 2">线上配送单</span>
              <span v-if="scope.row.orderType == 3">线上自提单</span>
              <span v-if="scope.row.orderType == 4">充值</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="100" prop="" label="动账类型">
            <template slot-scope="scope">
              <span v-if="scope.row.billType == 1">充值</span>
              <span v-if="scope.row.billType == 2">消费</span>
              <span v-if="scope.row.billType == 3">退款</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="100" prop="amount" label="金额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="afterBalance" label="账户余额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="160" prop="billTime" label="账单时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="showDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      memberName: "",
      memberCode: "",
      orderType: "",
      billType: "",
      orderCode: "",
      startTime: "",
      endTime: "",
      billTime: [],
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.memberCode = this.$route.query.code || ""

    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("marketingMemberBillList"))
    this.orderType = obj?.orderType
    this.billType = obj?.billType
    this.memberName = obj?.memberName
    this.memberCode = obj?.memberCode
    this.orderCode = obj?.orderCode
    this.startTime = obj?.startTime
    this.endTime = obj?.endTime
    this.billTime = obj?.billTime || []
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("marketingMemberBillList", {})

    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.shopMemberBillPageList, {
          params: {
            billType: this.billType || null,
            orderType: this.orderType || null,
            orderCode: this.orderCode,
            memberName: this.memberName,
            memberCode: this.memberCode,
            startTime: this.startTime,
            endTime: this.endTime,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list || []
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    showDetail(row) {
      let obj = {
        orderType: this.orderType,
        billType: this.billType,
        memberName: this.memberName,
        memberCode: this.memberCode,
        orderCode: this.orderCode,
        startTime: this.startTime,
        endTime: this.endTime,
        billTime: this.billTime || [],
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("marketingMemberBillList", obj)

      if (row.billType == 1) {
        this.$router.push({ path: "/marketing/member/recharge", query: { code: row.memberCode } })
      } else {
        this.$router.push({ path: "/marketing/detail/billDetail", query: { code: row.orderCode } })
      }
    },
    onChange(e) {
      this.startTime = e ? e[0] : ""
      this.endTime = e ? e[1] : ""
      this.onSearch()
    },
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.bills {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    .el-input {
      width: 200px;

      /deep/ .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    /deep/ .el-date-editor {
      width: 280px;
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;
    }
  }
}
</style>
